<template>
  <div class="pb">
  <div class="journal">

    <JournalCategory v-if="journals_data?.items_category?.length"
      :data="journals_data?.items_category"      
      />
    
    <JournalTop v-if="journals_data?.items_blog?.length"
      :data="journals_data.items_blog.slice(0,5)"      
      />
    
    <div class="posts">
      
      <JournalBoxItems 
        v-if="journals_data?.items_blog?.filter(b => {return b.group_name === 'latests'}).length"
        :data="journals_data?.items_blog.filter(b => {return b.group_name === 'latests'}).slice(0,6)"
        :title="$t('journal_view_latest_posts')"
        link="/journal/category/latests"
        class_list="latests small_boxes"
        class_item="box_small"
        />

      <JournalBoxItems  
        v-if="journals_data?.items_blog?.filter(b => {return b.group_name === 'top'}).length"
        :data="journals_data.items_blog.filter(b => {return b.group_name === 'top'}).slice(0,3)"
        :title="$t('journal_view_popular')"
        link="/journal/category/popular"
        class_list="popular middle_boxes"
        class_item="box_middle"
        />

      <JournalBoxItems 
        v-if="journals_data?.items_blog?.filter(b => {return b.group_name === 'update'}).length"
        :data="journals_data.items_blog.filter(b => {return b.group_name === 'update'})"
        :title="$t('journal_view_updates')"
        link="/journal/category/update"
        class_list="updates small_boxes"
        class_item="box_small"
        />

      <JournalBoxItems 
        v-if="journals_data?.items_blog?.filter(b => {return b.group_name === 'howto'}).length"
        :data="journals_data.items_blog.filter(b => {return b.group_name === 'howto'}).slice(0,3)"
        :title="$t('journal_view_grow_guide')"
        link="/journal/category/grow-guide"
        class_list="howto middle_boxes"
        class_item="box_middle"
        />

      <JournalBoxItems 
        v-if="journals_data?.items_blog?.filter(b => {return b.group_name === 'interview'}).length"
        :data="journals_data.items_blog.filter(b => {return b.group_name === 'interview'}).slice(0,6)"
        :title="$t('journal_view_interview')"
        link="/journal/category/interview"
        class_list="interview small_boxes"
        class_item="box_small"
        />

      <JournalBoxItems 
        v-if="journals_data?.items_blog?.filter(b => {return b.group_name === 'community'}).length"
        :data="journals_data.items_blog.filter(b => {return b.group_name === 'community'}).slice(0,3)"
        :title="$t('journal_view_community')"
        link="/journal/category/community"
        class_list="community middle_boxes"
        class_item="box_middle"
        />

      <client-only>     

        <JournalBoxItems v-if="more && more.length"
          :data="more"
          :title="$t('journal_view_more_articles')"
          link=""
          class_list="more small_boxes"
          class_item="box_list"
          />
                    
        <InfinityScroll 
          v-if="journals_data?.items_diary?.length >= limit"
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>

      </client-only> 

    </div>
    
    <div class="sdbr"> 

      <JournalVoteItems v-if="votes && votes.length"
        :data="votes"      
        />

      <JournalVacancy v-if="vacancy && vacancy.length"
        :data="vacancy"      
        />

      <JournalTagItems v-if="tags && tags.length"
        :data="tags"      
        />

    </div>

  </div>
  </div>
</template>



<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
const {$api, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)

const clearPagination = function(){     
  start.value = 0;  
}

const loadData = async function() {   
  
  const response = await $api.getJournals()  
  return response;  
}



const { pending: is_loading,  data: journals_data } = await useLazyAsyncData('journals_data', async () => await loadData())


watchArray([start], async ([new_start], added, removed) => {
  console.log('watcher');
  if(new_start == 0){
    journals_data.value.items_blog = [];
    is_ended.value = false;
  }
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    is_ended.value = true;  

  // journals_data.value.tags = [...journals_data.value.tags, ...dt.tags]
  // journals_data.value.items_category = [...journals_data.value.items_category, ...dt.items_category]
  // journals_data.value.votes = [...journals_data.value.votes, ...dt.votes]
  journals_data.value.items_blog = [...journals_data.value.items_blog, ...dt.items_blog]
  // journals_data.value.blog_vacancy = [...journals_data.value.blog_vacancy, ...dt.blog_vacancy]

  is_loading.value = false;

  $adv.apply();

}, {deep: true})



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


useHead(useNuxtApp().$head.getJournals());


 

</script>





<style scoped>

@import '@/assets/css/journal-list.css';

</style>
